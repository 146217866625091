
#top-header {

    #top-header-logo {
        margin: 10px 0;
        height: 51px;
        max-width: 351px;
        background: url('/images/site-stc/header-logo.png') no-repeat;
        background-size: contain;
        text-indent: -9999px;
    }

    #top-header-name-container {
        line-height: 1;

        #top-header-name {
            margin-top: 25px;
            color: #10026d;
            font-weight: bold;
        }
    }
}

$top-banner-color: #b9d3df;

.top-banner {
    background-color: $top-banner-color;
    border-bottom: solid 2px darken($top-banner-color,10%);
    margin-bottom: 1rem;

    h1 {
        margin: 0.75rem 0;
        font-size: 1.875rem;
    }

    h2 {
        margin: 0.75rem 0;
        font-size: 1.5rem;
    }
}

.bg-menu {
    background-color: #0780a7 !important;
}

#bottom-footer {
    color: #666;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 0.75rem;
}
